import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper, Link, TableSortLabel, Box } from '@material-ui/core';
import t from '../../common/localization';
import { formatDistance, formatSpeed, formatHours, formatDate } from '../../common/formatter';
import { useAttributePreference } from '../../common/preferences';
import { useDispatch, useSelector } from 'react-redux';
import { devicesActions } from '../../store';

const sortMethods = {
  startTime: "startTime",
  startOdometer: "startOdometer",
  endTime: "endTime",
  endOdometer: "endOdometer",
  distance: "distance",
  averageSpeed: "averageSpeed",
  maximumSpeed: "maximumSpeed",
  duration: "duration",
}

const callbacks = {}
callbacks[sortMethods.startTime] = (v) => v.startTime
callbacks[sortMethods.startOdometer] = (v) => v.startOdometer
callbacks[sortMethods.endTime] = (v) => v.endTime
callbacks[sortMethods.endOdometer] = (v) => v.endOdometer
callbacks[sortMethods.distance] = (v) => v.distance
callbacks[sortMethods.averageSpeed] = (v) => v.averageSpeed
callbacks[sortMethods.maximumSpeed] = (v) => v.maxSpeed
callbacks[sortMethods.duration] = (v) => v.duration

const TableCellSort = ({ sort, method, changeSort, children }) => {
  return (
    <TableCell>
      <TableSortLabel
        active={sort.method === method}
        direction={sort.desc || sort.method !== method ? 'desc' : 'asc'}
        onClick={() => changeSort(method)}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  )
}

const Trips = ({ onClick, items = [] }) => {
  const dispatch = useDispatch();
  const distanceUnit = useAttributePreference('distanceUnit');
  const [selectedRow, setSelectedRow] = useState(-1);
  const [sort, setSort] = useState({ method: sortMethods.startTime, desc: true });
  const isSelectedTrip = useSelector(state => state.devices.isSelectedTrip || false);
  const [trips, setTrips] = useState([])

  const onClickItem = (item, index) => () => {
    onClick(item);
    setSelectedRow(index)
    dispatch(devicesActions.selectTrip());
  }

  const onClickMaxSpeed = (event, item, index) => {
    onClick(item, true); 
    setSelectedRow(index); 
    dispatch(devicesActions.selectTrip());
    event.stopPropagation(); 
  }

  useEffect(() => {
    if (!isSelectedTrip) setSelectedRow(-1)
  }, [isSelectedTrip])

  const changeSort = (method) => {
    if (method === sort.method) setSort(state => ({ ...state, desc: !state.desc }))
    else setSort(state => ({ ...state, desc: true, method: method }))
  }

  useEffect(() => {
    const callback = callbacks[sort.method] ?? (() => null)
    const newTrips = [...items]
    newTrips.sort((a, b) => {
      if (callback(a) > callback(b)) return sort.desc ? 1 : -1;
      if (callback(a) < callback(b)) return sort.desc ? -1 : 1;
      return 0;
    });
    setTrips(newTrips)
  }, [sort, items])

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead >
          <TableRow>
            <TableCellSort
              sort={sort}
              method={sortMethods.startTime}
              changeSort={changeSort}
            >
              {t('reportStartTime')}
            </TableCellSort>
            <TableCellSort
              sort={sort}
              method={sortMethods.startOdometer}
              changeSort={changeSort}
            >
              {t('reportStartOdometer')}
            </TableCellSort>
            <TableCellSort
              sort={sort}
              method={sortMethods.endTime}
              changeSort={changeSort}
            >
              {t('reportEndTime')}
            </TableCellSort>
            <TableCellSort
              sort={sort}
              method={sortMethods.endOdometer}
              changeSort={changeSort}
            >
              {t('reportEndOdometer')}
            </TableCellSort>
            <TableCellSort
              sort={sort}
              method={sortMethods.distance}
              changeSort={changeSort}
            >
              {t('sharedDistance')}
            </TableCellSort>
            <TableCellSort
              sort={sort}
              method={sortMethods.averageSpeed}
              changeSort={changeSort}
            >
              {t('reportAverageSpeed')}
            </TableCellSort>
            <TableCellSort
              sort={sort}
              method={sortMethods.maximumSpeed}
              changeSort={changeSort}
            >
              {t('reportMaximumSpeed')}
            </TableCellSort>
            <TableCellSort
              sort={sort}
              method={sortMethods.duration}
              changeSort={changeSort}
            >
              {t('reportDuration')}
            </TableCellSort>
          </TableRow>
        </TableHead>
        <TableBody>
          {trips && trips.map((item, index) => (
            <TableRow key={index} onClick={onClickItem(item, index)} style={{ backgroundColor: (index === selectedRow) ? "lightgray" : "" }}>
              <TableCell>{formatDate(item.startTime)}</TableCell>
              <TableCell>{formatDistance(item.startOdometer, distanceUnit)}</TableCell>
              <TableCell>{formatDate(item.endTime)}</TableCell>
              <TableCell>{formatDistance(item.endOdometer, distanceUnit)}</TableCell>
              <TableCell>{formatDistance(item.distance, distanceUnit)}</TableCell>
              <TableCell>{formatSpeed(item.averageSpeed, "kmh")}</TableCell>
              <TableCell>
                <Link
                  component="button"
                  variant="body2"
                  onClick={(event) => onClickMaxSpeed(event, item, index)}
                  style={{ position: "static", color: "#00bcd4" }}
                >
                  {formatSpeed(item.maxSpeed, "kmh")}
                </Link>
              </TableCell>
              <TableCell>{formatHours(item.duration)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Trips;

import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper, TableSortLabel } from '@material-ui/core';
import t from '../../common/localization';
import { formatHours, formatDate } from '../../common/formatter';

const sortMethods = {
  startTime: "startTime",
  endTime: "endTime",
  duration: "duration",
  address: "address",
}

const callbacks = {}
callbacks[sortMethods.startTime] = (v) => v.startTime
callbacks[sortMethods.endTime] = (v) => v.endTime
callbacks[sortMethods.duration] = (v) => v.duration
callbacks[sortMethods.address] = (v) => v.address


const TableCellSort = ({ sort, method, changeSort, children }) => {
  return (
    <TableCell>
      <TableSortLabel
        active={sort.method === method}
        direction={sort.desc || sort.method !== method ? 'desc' : 'asc'}
        onClick={() => changeSort(method)}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  )
}

const Stops = (props) => {
  const { onClick, items } = props;
  const [selectedRow, setSelectedRow] = useState(-1);
  const [sort, setSort] = useState({ method: sortMethods.startTime, desc: true });
  const [trips, setTrips] = useState([])


  const changeSort = (method) => {
    if (method === sort.method) setSort(state => ({ ...state, desc: !state.desc }))
    else setSort(state => ({ ...state, desc: true, method: method }))
  }

  useEffect(() => {
    const callback = callbacks[sort.method] ?? (() => null)
    const newTrips = [...items]
    newTrips.sort((a, b) => {
      if (callback(a) > callback(b)) return sort.desc ? 1 : -1;
      if (callback(a) < callback(b)) return sort.desc ? -1 : 1;
      return 0;
    });
    setTrips(newTrips)
  }, [sort, items])

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellSort
              sort={sort}
              method={sortMethods.startTime}
              changeSort={changeSort}
            >
              {t('reportStartTime')}
            </TableCellSort>
            <TableCellSort
              sort={sort}
              method={sortMethods.endTime}
              changeSort={changeSort}
            >
              {t('reportEndTime')}
            </TableCellSort>
            <TableCellSort
              sort={sort}
              method={sortMethods.duration}
              changeSort={changeSort}
            >
              {t('reportDuration')}
            </TableCellSort>
            <TableCellSort
              sort={sort}
              method={sortMethods.address}
              changeSort={changeSort}
            >
              Адрес
            </TableCellSort>
          </TableRow>
        </TableHead>
        <TableBody>
          {trips && trips.map((item, index) => (
            <TableRow key={index} onClick={() => { onClick(item); setSelectedRow(index) }} style={{ backgroundColor: (index === selectedRow) ? "lightgray" : "" }}>
              <TableCell>{formatDate(item.startTime)}</TableCell>
              <TableCell>{formatDate(item.endTime)}</TableCell>
              <TableCell>{formatHours(item.duration)}</TableCell>
              <TableCell>{item.address}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Stops;

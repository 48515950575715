import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper, TableSortLabel } from '@material-ui/core';
import t from '../../common/localization';
import { formatPosition } from '../../common/formatter';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import RoomIcon from '@material-ui/icons/Room';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';

const eventIcons = {
  'deviceOnline': <RoomIcon />,
  'deviceUnknown': <LocationOffIcon />,
  'deviceOffline': <LocationOffIcon />,
  'deviceMoving': <SwapCallsIcon />,
  'deviceStopped': <LocalParkingIcon />,
}

const eventNames = {
  'deviceOnline': "Включено",
  'deviceUnknown': "Неизвестно",
  'deviceMoving': "Движение",
  'deviceStopped': "Остановка",
  'deviceOffline': "Отключено",
}

const sortMethods = {
  startTime: "startTime",
  event: "event",
}

const callbacks = {}
callbacks[sortMethods.startTime] = (v) => v.serverTime
callbacks[sortMethods.event] = (v) => v.type

const TableCellSort = ({ sort, method, changeSort, children }) => {
  return (
    <TableCell>
      <TableSortLabel
        active={sort.method === method}
        direction={sort.desc || sort.method !== method ? 'desc' : 'asc'}
        onClick={() => changeSort(method)}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  )
}

const Events = ({ items }) => {
  const [sort, setSort] = useState({ method: sortMethods.startTime, desc: true });
  const [trips, setTrips] = useState([])

  const changeSort = (method) => {
    if (method === sort.method) setSort(state => ({ ...state, desc: !state.desc }))
    else setSort(state => ({ ...state, desc: true, method: method }))
  }

  useEffect(() => {
    const callback = callbacks[sort.method] ?? (() => null)
    const newTrips = [...(items ?? [])]
    newTrips.sort((a, b) => {
      if (callback(a) > callback(b)) return sort.desc ? 1 : -1;
      if (callback(a) < callback(b)) return sort.desc ? -1 : 1;
      return 0;
    });
    setTrips(newTrips)
  }, [sort, items])

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCellSort
              sort={sort}
              method={sortMethods.startTime}
              changeSort={changeSort}
            >
              {t('reportStartTime')}
            </TableCellSort>
            <TableCellSort
              sort={sort}
              method={sortMethods.event}
              changeSort={changeSort}
            >
              Событие
            </TableCellSort>
          </TableRow>
        </TableHead>
        <TableBody>
          {(trips ?? []).map((item) => (
            <TableRow key={item.id} onClick={() => { console.log(item) }}>
              <TableCell>{eventIcons[item.type]}</TableCell>
              <TableCell>{formatPosition(item, 'serverTime')}</TableCell>
              <TableCell>{eventNames[item.type] ?? item.type}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Events;

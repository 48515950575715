import React, { useState } from 'react';
import MainToolbar from '../MainToolbar';
import { makeStyles, IconButton, Dialog } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import t from '../common/localization';
import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';
import { cursors } from '@mapbox/mapbox-gl-draw/src/constants';

const useStyles = makeStyles(theme => ({
  body: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    padding: "15px",
    gap: "10px",
    '@media (max-width: 1280px)': {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    '@media (max-width: 900px)': {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    '@media (max-width: 720px)': {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  card: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    background: "#f7f7f7",
    padding: "10px",
    borderRadius: "10px",
    border: "1px solid #e8eaee"
  },
  name: {
    flexGrow: "1",
  },
  iconButton: {
    background: "inherit",
    padding: "0",
    border: "0",
    cursor: "pointer"
  },
  icon: {
    width: '50px',
    height: '50px',
    minWidth: '50px',
    minHeight: '50px',
    verticalAlign: "top",
  },
  dialogIcon: {
    width: '500px',
    height: '500px',
    maxWidth: '100%',
    maxHeight: '100%',
    padding: "10px",
    boxSizing: "border-box",
    verticalAlign: "top",

  },
  buttons: {
    display: "flex"
  }
}));

const CategoriesView = ({ updateTimestamp, onEdit, onRemove }) => {
  const classes = useStyles();

  const [items, setItems] = useState([]);
  const [dialogItem, setDialogItem] = useState(null);

  useEffectAsync(async () => {
    const response = await fetch('/api/categories');
    if (response.ok) {
      setItems(await response.json());
    }
  }, [updateTimestamp]);

  return (
    <div className={classes.body}>
      {items.map((item) => (
        <div key={item.id} className={classes.card}>
          <button className={classes.iconButton} onClick={() => setDialogItem(item)}>
            <img className={classes.icon} src={`/api/categories/image/${item.imageName}`} alt="" />
          </button>
          <span className={classes.name}>{item.name}</span>
          <div className={classes.buttons}>
            <IconButton onClick={() => onEdit(item.id)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => onRemove(item.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      ))}
      <Dialog onClose={() => setDialogItem(null)} open={dialogItem !== null}>
        {dialogItem && <img className={classes.dialogIcon} src={`/api/categories/image/${dialogItem?.imageName}`} alt="" />}
      </Dialog>
    </div>
  );
}

const CategoriesPage = () => {
  return (
    <>
      <MainToolbar />
      <EditCollectionView content={CategoriesView} editPath="/settings/category" endpoint="categories" title={t('settingsCategories')} />
    </>
  );
}

export default CategoriesPage;
